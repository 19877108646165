import { graphql } from "gatsby";
import React, { } from "react";
import Layout from "../../components/layout";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { Trans } from "gatsby-plugin-react-i18next";
import { DownloadIcon } from "../../images/Icons";
import {
  VariableLogo,
} from "../../images/press/logo-and-usage/logo-varation";
import Breadcrumb from "../../components/breadcrumb";

import PrimaryLogo from "../../images/press/logo-and-usage/primary-logo.png";
import PrimaryLogoLayered from "../../images/press/logo-and-usage/primary-logo-layered.png";
import PrimaryLogoFavicon from "../../images/press/logo-and-usage/primary-logo-favicon.png";

import SecondaryLogo from "../../images/press/logo-and-usage/secondary-logo.png";
import SecondaryLogoLayered from "../../images/press/logo-and-usage/secondary-logo-layered.png";
import SecondaryLogoFavicon from "../../images/press/logo-and-usage/secondary-logo-favicon.png";

const LogoAndUsagePage = () => {

  return (
    <Layout pageTitle="Logo and its Usage - Moniepoint Inc.">
      <LogoUsageContainer>
        <div className="content">
          <Breadcrumb title="Press" link="/press" current="presspage.logo_and_its_usage" />
          <div className="top-div">
            <h2 className="title">
              <Trans>presspage.logo_and_its_usage</Trans>
            </h2>
          </div>
          <div className="logo-section">
            <div className="logo-item">
              <div className="">
                <StaticImage
                  alt="..."
                  src="../../images/press/logo-and-usage/primary-logo.png"
                  className="logo-container white fit"
                />
              </div>
              <div className="logo-details">
                <span>
                  <Trans>presspage.primary_logo</Trans>
                </span>
                <a href={PrimaryLogo} download="primary-logo.png" className="btn download-link">
                  <DownloadIcon size={16} />
                </a>
              </div>
            </div>
            <div className="logo-item">
              <div className="">
                <StaticImage
                  alt="..."
                  src="../../images/press/logo-and-usage/primary-logo-layered.png"
                  className="logo-container white fit"
                />
              </div>
              <div className="logo-details">
                <span>
                  <Trans>presspage.primary_logo_layered</Trans>
                </span>
                <a href={PrimaryLogoLayered} download="primary_logo_layered.png" className="btn download-link">
                  <DownloadIcon size={16} />
                </a>
              </div>
            </div>
            <div className="logo-item">
              <div className="">
                <StaticImage
                  alt="..."
                  src="../../images/press/logo-and-usage/primary-logo-favicon.png"
                  className="logo-container white fit"
                />
              </div>
              <div className="logo-details">
                <span>
                  <Trans>presspage.primary_logo_favicon</Trans>
                </span>
                <a href={PrimaryLogoFavicon} download="primary-logo-favicon.png" to="" className="btn download-link">
                  <DownloadIcon size={16} />
                </a>
              </div>
            </div>
            <div className="logo-item">
              <div className="">
                <StaticImage
                  alt="..."
                  src="../../images/press/logo-and-usage/secondary-logo.png"
                  className="logo-container primary fit"
                />
              </div>
              <div className="logo-details">
                <span>
                  <Trans>presspage.secondary_logo</Trans>
                </span>
                <a href={SecondaryLogo} download="secondary-logo-favicon.png" className="btn download-link">
                  <DownloadIcon size={16} />
                </a>
              </div>
            </div>
            <div className="logo-item">
              <div className="">
                <StaticImage
                  alt="..."
                  src="../../images/press/logo-and-usage/secondary-logo-layered.png"
                  className="logo-container primary fit"
                />
              </div>
              <div className="logo-details">
                <span>
                  <Trans>presspage.secondary_logo_layered</Trans>
                </span>
                <a href={SecondaryLogoLayered} download="secondary_logo_layered.png" className="btn download-link">
                  <DownloadIcon size={16} />
                </a>
              </div>
            </div>
            <div className="logo-item">
              <div className="">
                <StaticImage
                  alt="..."
                  src="../../images/press/logo-and-usage/secondary-logo-favicon.png"
                  className="logo-container primary fit"
                />
              </div>
              <div className="logo-details">
                <span>
                  <Trans>presspage.secondary_logo_favicon</Trans>
                </span>
                <a href={SecondaryLogoFavicon} download="secondary-logo-favicon.png" className="btn download-link">
                  <DownloadIcon size={16} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </LogoUsageContainer>
      <LogoColorUsageContainer>
        <div className="content">
          <div className="top-div">
            <h2 className="title">
              <Trans>presspage.logo_color_usage</Trans>
            </h2>
          </div>
          <div className="logo-section">
            <div className="logo-container primary">
              <VariableLogo
                textColor="white"
                iconColor="white"
                innerIconColor="#0357EE"
              />
            </div>
            <div className="logo-container secondary">
              <VariableLogo />
            </div>
            <div className="logo-container deep">
              <VariableLogo textColor="white" />
            </div>
            <div className="logo-container gray">
              <VariableLogo />
            </div>
            <div className="logo-container light-blue">
              <VariableLogo />
            </div>
            <div className="logo-container sky-blue">
              <VariableLogo />
            </div>
            <div className="logo-container wheat">
              <VariableLogo />
            </div>
            <div className="logo-container mustard">
              <VariableLogo />
            </div>
            <div className="logo-container peach">
              <VariableLogo />
            </div>
            <div className="logo-container rose">
              <VariableLogo />
            </div>
            <div className="logo-container orange">
              <VariableLogo
                textColor="white"
                iconColor="white"
                innerIconColor="#0357EE"
              />
            </div>
            <div className="logo-container red">
              <VariableLogo
                textColor="white"
                iconColor="white"
                innerIconColor="#0357EE"
              />
            </div>
            <div className="logo-container white">
              <VariableLogo />
            </div>
          </div>
        </div>
      </LogoColorUsageContainer>
      <LogoDontsContainer>
        <div className="content">
          <div className="top-div">
            <h2 className="title">
              <Trans>presspage.logo_donts</Trans>
            </h2>
            <ul className="list">
              <li className="">
                <strong>
                  <Trans>presspage.do_not</Trans>
                </strong>{" "}
                <Trans>presspage.stretch_the_logo</Trans>
              </li>
              <li className="">
                <strong>
                  <Trans>presspage.do_not</Trans>
                </strong>{" "}
                <Trans>presspage.change_opacity</Trans>
              </li>
              <li className="">
                <strong>
                  <Trans>presspage.do_not</Trans>
                </strong>{" "}
                <Trans>presspage.tilt_logo</Trans>
              </li>
              <li className="">
                <strong>
                  <Trans>presspage.do_not</Trans>
                </strong>{" "}
                <Trans>presspage.add_effects</Trans>
              </li>
            </ul>
          </div>
        </div>
      </LogoDontsContainer>
    </Layout>
  );
};

export default LogoAndUsagePage;

export const query = graphql`
   query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const LogoUsageContainer = styled.section`
  background-color: white;

  .content {
    max-width: 1440px;
    margin: auto;
    padding: 0 150px 32px;
    @media only screen and (max-width: 1024px) {
      padding: 0 60px 32px;
    }

    .top-div {
      margin-bottom: 16px;

      .title {
        font-family: "Founders Grotesk";
        font-size: 48px;
        font-weight: 500;
        line-height: 57px;
        max-width: 851px;
      }
    }
    .bottom-div {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      .text-content {
        font-size: 20px;
        font-weight: 400;
        line-height: 32.2px;
        letter-spacing: 0em;
        text-align: left;
        max-width: 586px;
        margin-bottom: 0;
      }
    }
    .logo-section {
      margin-top: 48px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-auto-rows: 1fr;
      grid-gap: 48px 32px;
      padding-bottom: 20px;

      @media only screen and (max-width: 1024px) {
        grid-template-columns: repeat(2, 1fr);
      }

      .logo-item {
        .logo-container {
          width: 100%;
          height: 235px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
          margin-bottom: 12px;

          &.white {
            border: 1px solid rgba(3, 87, 238, 0.1);
            background: white;
          }
          &.primary {
            background: rgba(3, 87, 238, 1);
          }

          &.fit {
            img {
              object-fit: contain !important;
            }
          }
        }
        .logo-details {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 16px;
          font-weight: 400;
          line-height: 26px;
          letter-spacing: -0.005em;
          text-align: left;
          color: rgba(0, 0, 0, 0.9);
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .content {
      padding: 0 20px 24px;

      .top-div {
        margin-bottom: 16px;
        .title {
          font-size: 32px;
          line-height: 37.76px;
          margin-top: 24px;
        }
      }
      .bottom-div {
        flex-direction: column;
        align-items: flex-start;
        .text-content {
          font-size: 14px;
          line-height: 21px;
          margin-bottom: 16px;
        }
      }

      .logo-section {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 24px;

        .logo-item {
          .logo-container {
            height: 170px;
          }
        }
      }
    }
  }
`;
const LogoColorUsageContainer = styled.section`
  background-color: white;

  .content {
    max-width: 1440px;
    margin: auto;
    padding: 32px 150px;
    @media only screen and (max-width: 1024px) {
      padding: 32px 60px;
    }

    .top-div {
      margin-bottom: 32px;
      .title {
        font-family: "Founders Grotesk";
        font-size: 36px;
        font-weight: 500;
        line-height: 42px;
        letter-spacing: 0em;
        text-align: left;
      }

      .description {
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(0, 0, 0, 0.9);
        max-width: 586px;
      }
    }
    .logo-section {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-auto-rows: 1fr;
      grid-gap: 32px;
      padding-bottom: 20px;

      @media only screen and (max-width: 1024px) {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 16px;
      }

      .logo-container {
        max-width: 261px;
        width: 100%;
        max-height: 120px;
        padding: 37px 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        background: rgba(255, 255, 255, 1);

        svg {
          width: 100%;
          height: auto;
        }

        &.primary {
          background-color: rgba(3, 87, 238, 1);
        }
        &.secondary {
          background-color: rgba(203, 221, 250, 1);
        }
        &.deep {
          background: rgba(11, 34, 86, 1);
        }
        &.gray {
          background: rgba(206, 211, 221, 1);
        }
        &.light-blue {
          background: rgba(205, 243, 255, 1);
        }
        &.sky-blue {
          background: rgba(5, 194, 255, 1);
        }
        &.wheat {
          background: rgba(255, 247, 222, 1);
        }
        &.mustard {
          background: rgba(255, 216, 92, 1);
        }
        &.peach {
          background: rgba(254, 226, 209, 1);
        }
        &.rose {
          background: rgba(249, 214, 205, 1);
        }
        &.orange {
          background: rgba(255, 130, 76, 1);
        }
        &.red {
          background: rgba(231, 64, 74, 1);
        }
        &.white {
          background: rgba(255, 255, 255, 1);
          border: 1px solid rgba(3, 87, 238, 0.1);
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .content {
      padding: 32px 20px;
      .top-div {
        .title {
          font-size: 24px;
          line-height: 28px;
        }
        .description {
          font-size: 14px;
          line-height: 21px;
        }
      }
      .logo-section {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 24px 22px;

        .logo-container {
          padding: 16px 23px;

          svg {
            height: 31px;
            width: auto;
          }
        }
      }
    }
  }
`;
const LogoDontsContainer = styled.section`
  background-color: white;

  .content {
    max-width: 1440px;
    margin: auto;
    padding: 32px 150px 150px;
    @media only screen and (max-width: 1024px) {
      padding: 32px 60px 150px;
    }

    .top-div {
      margin-bottom: 32px;
      .title {
        font-family: "Founders Grotesk";
        font-size: 36px;
        font-weight: 500;
        line-height: 42px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 48px;
      }

      .description {
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(0, 0, 0, 0.9);
      }

      .list {
        padding-left: 1rem;

        li::marker {
          color: #0357ee;
          font-size: 22px;
        }

        li {
          font-size: 20px;
          margin-bottom: 24px;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .content {
      padding: 32px 20px 64px;
      .top-div {
        .title {
          font-size: 24px;
          line-height: 28px;
          margin-bottom: 24px;
        }

        .list {
          padding-left: 1rem;

          li::marker {
            color: #0357ee;
            font-size: 22px;
          }

          li {
            font-size: 16px;
          }
        }
      }
    }
  }
`;
