import { Link } from "gatsby-plugin-react-i18next";
import React from "react";
import { Trans } from "react-i18next";
import styled from "styled-components";
import { ArrowLeftIcon, CaretRightIcon } from "../images/Icons";

const Breadcrumb = ({ title, link, current }) => {
    return (
        <BreadcrumbContainer>
            <div className="desktop-view">
                <Link to={link} className="home-text">
                    <Trans>{title}</Trans>
                </Link>
                <CaretRightIcon size={20} />
                <span className="">
                    <Trans>{current}</Trans>
                </span>
            </div>
            <Link to={link} className="mobile-view">
                <ArrowLeftIcon />
                <span className="">
                    <Trans>{title}</Trans>
                </span>
            </Link>
        </BreadcrumbContainer >
    );
};

export default Breadcrumb;

const BreadcrumbContainer = styled.div`
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  margin: 48px 0;

  .desktop-view,
  .mobile-view {
    display: flex;;
    align-items: center;
    gap: 8px;
  }
  

  .mobile-view {
    display: none;
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
    color: #000000;
  }

  .home-text {
    text-decoration: none;
  }

  @media only screen and (max-width: 768px) {
    margin: 16px 0 24px;
    .mobile-view {
      display: flex;
    }
    .desktop-view {
      display: none;
    }
  }
`;
