import React from "react";

export const VariableIconLogo = ({ size = 46, iconColor = "#0357EE", innerIconColor = "white" }) => (
    <svg style={{ height: size, width: 'auto' }} width="246" height="244" viewBox="0 0 246 244" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M122.978 243.589H122.099C65.2953 243.444 27.2308 231.657 19.3251 224.381C10.9801 216.96 0 178.545 0 121.795C0 65.1899 10.9801 26.6289 19.3251 19.2077C27.2308 11.9321 65.4417 0.145513 122.099 0H122.978C179.782 0.145513 217.846 11.9321 225.752 19.2077C234.097 26.6289 245.077 65.1899 245.077 121.795C245.077 178.399 234.097 216.96 225.752 224.381C217.846 231.803 179.782 243.589 122.978 243.589Z" fill={iconColor} />
        <path d="M179.05 129.068L174.365 72.1719H146.109C146.109 72.1719 124.588 115.971 122.685 121.937H122.392L99.6998 72.1719H71.2979L66.613 121.501L62.6602 171.557H79.9356C87.4021 171.557 90.3301 168.356 90.9157 160.498C90.9157 160.498 93.8437 114.662 93.4045 107.968H93.6973L94.5758 111.024C95.747 115.098 111.851 154.241 111.851 154.241H133.079C133.079 154.241 149.184 115.68 151.233 107.968H151.526L154.015 160.498C154.601 168.356 157.675 171.557 164.995 171.557H182.27L179.05 129.068Z" fill={innerIconColor} />
    </svg>


)


export const VariableLogo = ({ textColor = "black", iconColor = "#0357EE", innerIconColor = "white" }) => (
    <svg width="193" height="47" viewBox="0 0 193 47" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M81.7039 21.1153C83.1969 19.7689 85.0216 19.1094 87.2057 19.1094C89.4174 19.1094 91.2421 19.7689 92.735 21.1153C94.228 22.4343 94.9744 24.1655 94.9744 26.2814C94.9744 28.3698 94.228 30.101 92.735 31.42C91.2697 32.7115 89.4451 33.371 87.2333 33.371C85.0216 33.371 83.1969 32.7115 81.7039 31.42C80.2387 30.101 79.4922 28.3698 79.4922 26.2814C79.4922 24.1655 80.2387 22.4343 81.7039 21.1153ZM87.2333 29.5514C88.9751 29.5514 90.3574 28.2599 90.3574 26.2814C90.3574 24.2754 88.9751 22.929 87.2333 22.929C85.4916 22.929 84.1092 24.2754 84.1092 26.2814C84.1092 28.2599 85.5192 29.5514 87.2333 29.5514Z" fill={textColor} />
        <path d="M105.399 19.1094C103.464 19.1094 101.998 19.9063 101.058 21.4726H101.003L101.031 21.1428V20.9505C101.031 19.9887 100.367 19.4116 99.123 19.4116H95.0312V23.2587H96.0542C96.4689 23.2587 96.6348 23.4511 96.6348 23.8358V33.0413H101.252V26.9959C101.252 26.3089 101.335 25.7044 101.528 25.2097C101.971 23.9182 102.883 23.2587 104.238 23.2587C105.261 23.2587 105.758 23.8633 105.758 25.0449V31.1177C105.758 32.4642 106.339 33.0413 107.694 33.0413H110.348V24.2205C110.348 20.7856 108.689 19.1094 105.399 19.1094Z" fill={textColor} />
        <path d="M113.638 14.1367V17.8464H117.509V14.1367H113.638ZM115.961 19.4127H111.703V23.2598H112.726C113.113 23.2598 113.307 23.4521 113.307 23.8368V31.1188C113.307 32.4653 113.887 33.0423 115.242 33.0423H117.896V21.3637C117.896 19.9898 117.315 19.4127 115.961 19.4127Z" fill={textColor} />
        <path d="M121.461 21.1428C122.761 19.7964 124.503 19.1094 126.659 19.1094C128.622 19.1094 130.17 19.7139 131.304 20.9505C132.437 22.1596 133.018 23.7533 133.018 25.6769C133.018 26.0066 132.99 26.4738 132.907 27.1058H124.254C124.586 28.6996 125.885 29.5514 127.461 29.5514C128.594 29.5514 129.866 29.1118 131.248 28.1775L132.963 31.3925C131.497 32.6291 129.23 33.371 127.101 33.371C124.779 33.371 122.954 32.6566 121.6 31.2551C120.245 29.8537 119.581 28.15 119.581 26.2265C119.526 24.193 120.162 22.4893 121.461 21.1428ZM128.456 24.4953C128.456 23.3412 127.71 22.4618 126.576 22.4618C125.415 22.4618 124.668 23.1488 124.364 24.4953H128.456Z" fill={textColor} />
        <path d="M134.649 19.6618H137.055V20.8434C137.055 21.393 136.972 21.8601 136.972 21.8601H137.027C137.884 20.2388 139.598 19.332 141.561 19.332C145.211 19.332 147.478 22.1899 147.478 26.3392C147.478 30.5985 144.906 33.3464 141.368 33.3464C139.654 33.3464 138.105 32.4945 137.193 31.0656H137.138C137.138 31.0656 137.248 31.5327 137.248 32.2197V38.3201H134.677V19.6618H134.649ZM140.981 31.148C143.109 31.148 144.879 29.4169 144.879 26.3942C144.879 23.4814 143.303 21.6128 141.063 21.6128C139.045 21.6128 137.165 23.0417 137.165 26.4216C137.165 28.7848 138.492 31.148 140.981 31.148Z" fill={textColor} />
        <path d="M155.965 19.332C160.002 19.332 163.236 22.2723 163.236 26.3117C163.236 30.3786 159.974 33.3463 155.993 33.3463C151.956 33.3463 148.722 30.3786 148.722 26.3117C148.722 22.2723 151.984 19.332 155.965 19.332ZM155.993 31.148C158.536 31.148 160.637 29.1421 160.637 26.3392C160.637 23.5638 158.536 21.5853 155.993 21.5853C153.422 21.5853 151.348 23.5638 151.348 26.3392C151.376 29.1421 153.449 31.148 155.993 31.148Z" fill={textColor} />
        <path d="M165.255 14.3594H167.909V16.9424H165.255V14.3594ZM165.31 19.6628H167.881V33.0726H165.31V19.6628Z" fill={textColor} />
        <path d="M170.784 19.6618H173.3V21.4754C173.3 22.025 173.217 22.4921 173.217 22.4921H173.272C173.77 21.338 175.429 19.332 178.387 19.332C181.566 19.332 183.004 21.0632 183.004 24.4706V33.0441H180.433V25.0477C180.433 23.1791 180.074 21.6952 177.889 21.6952C175.263 21.6952 173.383 23.8661 173.383 26.614V33.0441H170.812V19.6618H170.784Z" fill={textColor} />
        <path d="M186.349 21.8885H184.579V19.8001H186.404V15.9805H188.947V19.8001H192.21V21.8885H188.947V27.8789C188.947 30.5444 190.827 30.9016 191.823 30.9016C192.016 30.9016 192.182 30.9016 192.431 30.8467V33.1274C192.21 33.1549 191.933 33.2099 191.519 33.2099C189.777 33.2099 186.349 32.6603 186.349 28.1812V21.8885Z" fill={textColor} />
        <path d="M78.0277 24.9635L77.143 14.1367H71.7518C71.7518 14.1367 67.6324 22.4904 67.3007 23.617H67.2454L62.9325 14.1367H57.5413L56.6566 23.5346L55.9102 33.0698H59.2001C60.6101 33.0698 61.1907 32.4653 61.3013 30.9814C61.3013 30.9814 61.8542 22.2431 61.7713 20.979H61.8266L61.9925 21.5561C62.2137 22.3255 65.2825 29.7723 65.2825 29.7723H69.3189C69.3189 29.7723 72.3877 22.4354 72.7748 20.979H72.8301L73.3001 30.9814C73.4107 32.4653 73.9912 33.0698 75.4012 33.0698H78.6912L78.0277 24.9635Z" fill={textColor} />
        <path d="M23.2234 46.5898H23.0575C12.3305 46.5624 5.14233 44.3366 3.64939 42.9626C2.07352 41.5612 0 34.3067 0 23.5898C0 12.9005 2.07352 5.61852 3.64939 4.21708C5.14233 2.84313 12.3582 0.617323 23.0575 0.589844H23.2234C33.9504 0.617323 41.1386 2.84313 42.6316 4.21708C44.2074 5.61852 46.281 12.9005 46.281 23.5898C46.281 34.2792 44.2074 41.5612 42.6316 42.9626C41.1386 44.364 33.9504 46.5898 23.2234 46.5898Z" fill={iconColor} />
        <path d="M33.8123 24.9631L32.9276 14.2188H27.5917C27.5917 14.2188 23.5276 22.49 23.1682 23.6166H23.1129L18.8277 14.2188H13.4642L12.5795 23.5342L11.833 32.987H15.0953C16.5053 32.987 17.0583 32.3824 17.1689 30.8986C17.1689 30.8986 17.7218 22.2426 17.6389 20.9786H17.6942L17.86 21.5557C18.0812 22.3251 21.1224 29.717 21.1224 29.717H25.1312C25.1312 29.717 28.1723 22.435 28.5594 20.9786H28.6147L29.0847 30.8986C29.1953 32.3824 29.7758 32.987 31.1582 32.987H34.4205L33.8123 24.9631Z" fill={innerIconColor} />
    </svg>
)

